<template>
  <!-- eslint-disable -->
  <b-card>
    <div>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="updateUser(userData.id)">
          <b-row>
            <!-- Field: Full Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Nombres" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.name"
                >
                  <b-form-input
                    @keypress="isLetter"
                    placeholder="Nombres"
                    id="name"
                    v-model="userData.name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombres es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Apellidos" label-for="last_name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.last_name"
                >
                  <b-form-input
                    @keypress="isLetter"
                    id="last_name"
                    placeholder="Apellidos"
                    v-model="userData.last_name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo apellidos es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  rules="requeridoE|correo"
                  name="userData.email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    type="email"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Role -->
            <b-col cols="12" md="4">
              <b-form-group label="Rol" label-for="user-role">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.role"
                >
                  <v-select
                    v-model="userData.role"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="user-role"
                    :state="errors.length > 0 ? false : null"
                    :class="{
                      'border border-danger rounded': errors.length > 0,
                    }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo rol es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Company -->
            <b-col cols="12" md="4">
              <b-form-group label="Empresa" label-for="user-company">
                <v-select
                  v-model="userData.company_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companyOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  placeholder="Ningun empresa seleccionada"
                  input-id="user-company"
                />
              </b-form-group>
            </b-col>
            <!-- Field: PASSWORD -->
            <b-col cols="12" md="4">
              <b-form-group
                class="mt-1"
                label="Contraseña"
                label-for="password"
              >
                <b-form-checkbox
                  v-model="selected"
                  :value="true"
                  @change="changePass(selected)"
                  class="custom-control-primary"
                >
                  Cambiar contraseña
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="selected">
            <b-col cols="12" md="4">
              <b-form-group label="Nueva Contraseña" label-for="new-pass">
                <validation-provider
                  #default="{ errors }"
                  :rules="{ required: selected }"
                  name="upassword"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{
                      'border border-danger rounded': errors.length > 0,
                    }"
                  >
                    <b-form-input
                      :type="passwordType"
                      id="new-pass"
                      autocomplete="new-password"
                      v-model="upassword"
                      placeholder="Nueva contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo empresa es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Guadar cambios
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, requeridoE, correo } from "@validations";
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: {},
      required,
      requeridoE,
      correo,
      upassword: "",
      currentPage: 1,
      showEntrie: 10,
      sort: "id",
      order: "desc",
      selected: false,
      passwordType: "password",
      companyOptions: [],
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "Contratista", value: "contratista" },
        { label: "Regulador", value: "regulador" },
        { label: "Jefe de Area", value: "jefe_area" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    passwordToggleIconRetype() {
      return this.passwordType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordRetype() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    isLetter(event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        event.preventDefault();
      }
    },
    changePass(val) {
      if (val == false) {
        this.upassword = "";
      }
    },
    async getData() {
      const resp = await UserService.getUser(
        this.$route.params.id,
        this.$store
      );
      console.log("data", resp);
      if (resp.success) {
        this.userData = resp.data;
      }
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp1 = await CompanyService.getCompanys(url, this.$store);
      if (resp1) {
        resp1.rows.forEach((element) => {
          this.companyOptions.push({
            label: element.business_name,
            value: element.id,
          });
        });
      }
    },
    async updateUser(id) {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
      } else {
        if (this.selected == true && this.upassword != "") {
          this.userData.change_password = true;
          this.userData.password = this.upassword;
        } else {
          this.userData.change_password = false;
          this.userData.password = "";
        }
        const resp = await UserService.updateUser(
          id,
          this.userData,
          this.$store
        );
        if (resp.success) {
          this.$swal({
            title: "Editado",
            text: "El usuario ha sido actualizado",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push("/users/list");
        } else {
          this.$swal({
            title: "Advertencia!",
            text: resp.msg,
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
  },
};
</script>

<style></style>
