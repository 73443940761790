var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUser(_vm.userData.id)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Nombres","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"userData.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border border-danger': errors.length > 0 },attrs:{"placeholder":"Nombres","id":"name","state":errors.length > 0 ? false : null},on:{"keypress":_vm.isLetter},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("El campo nombres es requerido")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"last_name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"userData.last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border border-danger': errors.length > 0 },attrs:{"id":"last_name","placeholder":"Apellidos","state":errors.length > 0 ? false : null},on:{"keypress":_vm.isLetter},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("El campo apellidos es requerido")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"requeridoE|correo","name":"userData.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border border-danger': errors.length > 0 },attrs:{"id":"email","type":"email","placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Rol","label-for":"user-role"}},[_c('validation-provider',{attrs:{"rules":"required","name":"userData.role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{
                    'border border-danger rounded': errors.length > 0,
                  },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-role","state":errors.length > 0 ? false : null},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("El campo rol es requerido")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"user-company"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.companyOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Ningun empresa seleccionada","input-id":"user-company"},model:{value:(_vm.userData.company_id),callback:function ($$v) {_vm.$set(_vm.userData, "company_id", $$v)},expression:"userData.company_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Contraseña","label-for":"password"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true},on:{"change":function($event){return _vm.changePass(_vm.selected)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" Cambiar contraseña ")])],1)],1)],1),(_vm.selected)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Nueva Contraseña","label-for":"new-pass"}},[_c('validation-provider',{attrs:{"rules":{ required: _vm.selected },"name":"upassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                    'border border-danger rounded': errors.length > 0,
                  }},[_c('b-form-input',{attrs:{"type":_vm.passwordType,"id":"new-pass","autocomplete":"new-password","placeholder":"Nueva contraseña"},model:{value:(_vm.upassword),callback:function ($$v) {_vm.upassword=$$v},expression:"upassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("El campo empresa es requerido")]):_vm._e()]}}],null,false,941706817)})],1)],1)],1):_vm._e(),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Guadar cambios ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }